import { useEffect } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Pakistan from "../../assets/Pakistan.jpeg";
import Canada from "../../assets/Canada.webp";
import AOS from "aos";
import "aos/dist/aos.css";

const Contact = () => {
  useEffect(() => {
    AOS.init({
      duration: 1200, // Animation duration in milliseconds
    });
  }, []);

  return (
    <div class="contact-us-section">
      <h2>Contact Us</h2>
      <div className="contact-us-alignment">
        <div className="container position-relative" data-aos="fade-right">
          <img
            src={Canada}
            className="hero-img"
            style={{ height: "30vh", width: "400px" }}
            alt="image7"
          />
          <h3 style={{ fontSize: "28px", marginBottom: "5px" }}>Canada</h3>
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <FontAwesomeIcon
              icon="fa-solid fa-location-dot"
              style={{ marginRight: "10px", marginTop: "-3px" }}
            />
            <p style={{ fontSize: "16px" }}>
              342 Silver Court, Milton, Ontario L9t 0N2, Canada
            </p>
          </div>
          <p className="phone_number_alignment" style={{ fontSize: "16px" }}>
            <span style={{ fontWeight: "600" }}>Phone:</span> +1 (647) 915-1599
          </p>
        </div>

        <div className="container position-relative" data-aos="fade-left">
          <img
            src={[Pakistan]}
            className="hero-img"
            style={{ height: "30vh", width: "400px" }}
            alt="image8"
          />
          <h3 style={{ fontSize: "28px", marginBottom: "5px" }}>Pakistan</h3>
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <FontAwesomeIcon
              icon="fa-solid fa-location-dot"
              style={{ marginRight: "10px", marginTop: "-3px" }}
            />
            <p style={{ fontSize: "16px", textAlign: "justify" }}>
              Office No-1 &amp; 2, 4th Floor, Fantom Mall,
              <br /> I-8 Markaz, Islamabad, Pakistan
            </p>
          </div>
          <p style={{ fontSize: "16px", marginTop: "0" }}>
            <span style={{ fontWeight: "600" }}>Phone:</span> +92 51 2722257
          </p>
        </div>
      </div>
    </div>
  );
};

export default Contact;
