import { useEffect } from "react";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import AOS from "aos";
import "aos/dist/aos.css";

const Services = ({ title }) => {
  useEffect(() => {
    AOS.init({
      duration: 1200, // Animation duration in milliseconds
    });
  }, []);

  return (
    <div class="services-section">
      {title === 1 && <h2>Our Services</h2>}
      <div class="cards-container">
        <div class="card container position-relative" data-aos="fade-up">
          <div class="card-icon">
            <FontAwesomeIcon
              icon="fa-solid fa-computer"
              style={{ fontSize: "40px" }}
            />
          </div>
          <div class="card-content">
            <h3>Web Development</h3>
            <p>
              Transform your online presence with our comprehensive web
              development services. We create responsive, user-friendly websites
              tailored to meet your business needs, ensuring a seamless user
              experience and robust functionality.
            </p>
            <Link to="/web-development" style={{ textDecoration: "none" }}>
              <div class="card-learn-more">
                <p>Learn more</p>
                <FontAwesomeIcon
                  icon="fa-solid fa-arrow-right"
                  style={{ fontSize: "20px", color: "#004AAD" }}
                />
              </div>
            </Link>
          </div>
        </div>
        <div class="card container position-relative" data-aos="fade-up">
          <div class="card-icon">
            <FontAwesomeIcon
              icon="fa-solid fa-mobile-screen"
              style={{ fontSize: "40px" }}
            />
          </div>
          <div class="card-content">
            <h3>App Development</h3>
            <p>
              Bring your ideas to life with our custom app development
              solutions. We specialize in creating innovative, high-performance
              mobile and web applications designed to engage users and drive
              business growth.
            </p>
            <Link to="/app-development" style={{ textDecoration: "none" }}>
              <div class="card-learn-more">
                <p>Learn more</p>
                <FontAwesomeIcon
                  icon="fa-solid fa-arrow-right"
                  style={{ fontSize: "20px", color: "#004AAD" }}
                />
              </div>
            </Link>
          </div>
        </div>
        <div class="card container position-relative" data-aos="fade-up">
          <div class="card-icon">
            <FontAwesomeIcon
              icon="fa-solid fa-money-bill-trend-up"
              style={{ fontSize: "40px" }}
            />
          </div>
          <div class="card-content">
            <h3>Digital Marketing</h3>
            <p>
              Elevate your brand with our expert digital marketing services.
              From SEO and social media management to content creation and PPC
              campaigns, we develop strategies that increase your online
              visibility and boost your ROI.
            </p>
            <Link to="/digital-marketing" style={{ textDecoration: "none" }}>
              <div class="card-learn-more">
                <p>Learn more</p>
                <FontAwesomeIcon
                  icon="fa-solid fa-arrow-right"
                  style={{ fontSize: "20px", color: "#004AAD" }}
                />
              </div>
            </Link>
          </div>
        </div>
        <div class="card container position-relative" data-aos="fade-up">
          <div class="card-icon">
            <FontAwesomeIcon
              icon="fa-brands fa-uikit"
              style={{ fontSize: "40px" }}
            />
          </div>
          <div class="card-content">
            <h3>UI/UX Designing</h3>
            <p>
              Enhance your digital presence with our expert UI/UX design
              services. We create intuitive, user-friendly interfaces that boost
              engagement and satisfaction. Our stunning designs are tailored to
              meet your specific needs.
            </p>
            <Link to="/ui-ux-designing" style={{ textDecoration: "none" }}>
              <div class="card-learn-more">
                <p>Learn more</p>
                <FontAwesomeIcon
                  icon="fa-solid fa-arrow-right"
                  style={{ fontSize: "20px", color: "#004AAD" }}
                />
              </div>
            </Link>
          </div>
        </div>
        <div class="card container position-relative" data-aos="fade-up">
          <div class="card-icon">
            <FontAwesomeIcon
              icon="fa-brands fa-wordpress"
              style={{ fontSize: "40px" }}
            />
          </div>
          <div class="card-content">
            <h3>WordPress Development</h3>
            <p>
              Build a flexible website with our WordPress development services.
              We create custom themes, plugins, and functionality to meet your
              goals. Our team ensures your site is secure, responsive, and
              optimized for performance.
            </p>
            <Link
              to="/wordpress-development"
              style={{ textDecoration: "none" }}
            >
              <div class="card-learn-more">
                <p>Learn more</p>
                <FontAwesomeIcon
                  icon="fa-solid fa-arrow-right"
                  style={{ fontSize: "20px", color: "#004AAD" }}
                />
              </div>
            </Link>
          </div>
        </div>
        <div class="card container position-relative" data-aos="fade-up">
          <div class="card-icon">
            <FontAwesomeIcon
              icon="fa-brands fa-figma"
              style={{ fontSize: "40px" }}
            />
          </div>
          <div class="card-content">
            <h3>Graphic Designing</h3>
            <p>
              Elevate your brand identity with our comprehensive graphic design
              services. From logos and brochures to social media graphics and
              marketing materials, we deliver visually appealing designs that
              capture your brand's essence.
            </p>
            <Link to="/graphic-designing" style={{ textDecoration: "none" }}>
              <div class="card-learn-more">
                <p>Learn more</p>
                <FontAwesomeIcon
                  icon="fa-solid fa-arrow-right"
                  style={{ fontSize: "20px", color: "#004AAD" }}
                />
              </div>
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Services;
