import { useState } from "react";
import Contact from "../HomePage/Contact";
import "./ContactPage.css";
import emailjs from "emailjs-com";

const ContactPage = () => {
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    phone: "",
    company: "",
    message: "",
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const [errors, setErrors] = useState({});
  const [isSubmitted, setIsSubmitted] = useState(false);
  //   const { name, email, phone, message } = formData;

  //   // Validate Name
  //   if (name.trim() === "") {
  //     alert("Name is required.");
  //     return false;
  //   }

  //   // Validate Email
  //   const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  //   if (!emailRegex.test(email)) {
  //     alert("Please enter a valid email address.");
  //     return false;
  //   }

  //   // Validate Phone (optional, can be empty but must be valid if provided)
  //   const phoneRegex = /^[0-9]{10}$/; // assuming US phone number format
  //   if (phone && !phoneRegex.test(phone)) {
  //     alert("Please enter a valid 10-digit phone number.");
  //     return false;
  //   }

  //   // Validate Message
  //   if (message.trim() === "") {
  //     alert("Message is required.");
  //     return false;
  //   }

  //   // If all validations pass
  //   return true;
  // };

  const validateForm = () => {
    const { name, email, phone, message } = formData;
    let newErrors = {};

    // Validate Name
    if (name.trim() === "") {
      newErrors.name = "Name is required.";
    }

    // Validate Email
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!emailRegex.test(email)) {
      newErrors.email = "Please enter a valid email address.";
    }

    // Validate Phone (optional, can be empty but must be valid if provided)
    const phoneRegex = /^[\d\s()+-]+$/;
    if (phone && !phoneRegex.test(phone)) {
      newErrors.phone = "Please enter a valid phone number.";
    }

    // Validate Message
    if (message.trim() === "") {
      newErrors.message = "Message is required.";
    }

    setErrors(newErrors);

    // If no errors, return true
    return Object.keys(newErrors).length === 0;
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (validateForm()) {
      emailjs
        .sendForm(
          "service_88gvenr",
          "template_pzvtyg3",
          e.target,
          "udoUfSSRfzAZBebC_"
        )
        .then(
          (result) => {
            setIsSubmitted(true);
            setFormData({
              name: "",
              email: "",
              phone: "",
              company: "",
              message: "",
            });
            setErrors({});
            //window.location.reload(); //This is if you still want the page to reload (since e.preventDefault() cancelled that behavior)
          },
          (error) => {
            console.log(error.text);
          }
        );
    }
  };

  return (
    <div>
      <div>
        <div className="hero-section contact-hero-section">
          <div className="hero-title">
            <h2 className="hero-text">Contact Us</h2>
            <p
              className="hero-text"
              style={{
                fontSize: "19px",
                lineHeight: "1.3",
                fontWeight: "300",
                fontFamily: "system-ui",
              }}
            >
              At Ebaad Digital, we specialize in delivering exceptional digital
              experiences. From web development to design and maintenance, our
              experts are here to help. Contact us today to learn more about how
              we can achieve your software development goals.
            </p>
          </div>
          <div>
            <form id="contact-form" onSubmit={handleSubmit} noValidate>
              <div>
                <label htmlFor="name">Name*</label>
                {errors.name && (
                  <span
                    style={{
                      color: "red",
                      margin: "5px 0",
                      fontSize: "12px",
                      float: "right",
                    }}
                  >
                    {errors.name}
                  </span>
                )}{" "}
                {/* Error message */}
                <input
                  type="text"
                  id="name"
                  name="name"
                  value={formData.name}
                  onChange={handleChange}
                  style={{
                    borderColor: errors.name ? "red" : "",
                  }}
                  required
                />
              </div>
              <div>
                <label htmlFor="email">Email*</label>
                {errors.email && (
                  <span
                    style={{
                      color: "red",
                      margin: "5px 0",
                      fontSize: "12px",
                      float: "right",
                    }}
                  >
                    {errors.email}
                  </span>
                )}{" "}
                {/* Error message */}
                <input
                  type="email"
                  id="email"
                  name="email"
                  value={formData.email}
                  onChange={handleChange}
                  style={{
                    borderColor: errors.email ? "red" : "",
                  }}
                  required
                />
              </div>
              <div>
                <label htmlFor="phone">Phone</label>
                {errors.phone && (
                  <span
                    style={{
                      color: "red",
                      margin: "5px 0",
                      fontSize: "12px",
                      float: "right",
                    }}
                  >
                    {errors.phone}
                  </span>
                )}{" "}
                {/* Error message */}
                <input
                  type="tel"
                  id="phone"
                  name="phone"
                  value={formData.phone}
                  onChange={handleChange}
                  style={{
                    borderColor: errors.phone ? "red" : "",
                  }}
                />
              </div>
              <div>
                <label htmlFor="company">Company Name</label>
                <input
                  type="text"
                  id="company"
                  name="company"
                  value={formData.company}
                  onChange={handleChange}
                />
              </div>
              <div>
                <label htmlFor="message">Message</label>
                {errors.message && (
                  <span
                    style={{
                      color: "red",
                      margin: "5px 0",
                      fontSize: "12px",
                      float: "right",
                    }}
                  >
                    {errors.message}
                  </span>
                )}{" "}
                {/* Error message */}
                <textarea
                  id="message"
                  name="message"
                  value={formData.message}
                  onChange={handleChange}
                  style={{
                    borderColor: errors.message ? "red" : "",
                  }}
                  required
                ></textarea>
              </div>
              <button type="submit">Submit</button>
              {isSubmitted && (
                <div style={{ color: "green", marginTop: "5px", fontSize: "12px", marginBottom: "0" }}>
                  Thank you! Your message has been sent successfully.
                </div>
              )}
            </form>
          </div>
        </div>
      </div>

      <div className="contact-mobile-view">
        <Contact />
      </div>
    </div>
  );
};

export default ContactPage;
