import React from "react";
import "./Footer.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const Footer = () => {
  return (
    <footer className="footer">
      <div className="footer-layout">
        <div>
          <FontAwesomeIcon
            icon="fa-solid fa-envelope"
            style={{ marginRight: "5px" }}
          />
          <span>Email Us</span>
          <p style={{margin: "0", lineHeight: "2"}}>info@ebaaddigital.com</p>
        </div>
        <div>
          <FontAwesomeIcon icon="fa-brands fa-facebook-f" className="social-icon" />
          <FontAwesomeIcon icon="fa-brands fa-instagram" className="social-icon" />
          <FontAwesomeIcon icon="fa-brands fa-twitter" className="social-icon" />
          <FontAwesomeIcon icon="fa-brands fa-linkedin" className="social-icon" />
        </div>
      </div>
      <p>Copyright &copy; 2024 | Ebaad Digital</p>
    </footer>
  );
};

export default Footer;
