import Services from "../HomePage/Services";

import "./ServicesPage.css";

const ServicesPage = () => {
  return (
    <>
      <div className="services-page-hero">
        <div className="services-page-hero-content">
          <h1 className="services-page-title">Services</h1>
          <h2 className="services-page-content">
            At Ebaad Digital, we offer a comprehensive suite of digital solutions
            designed to elevate your business. Whether you're looking to build a
            new website, create a stunning app, or enhance your brand's visual
            identity, our team of experts is here to help. Explore our services
            and discover how we can bring your vision to life.
          </h2>
        </div>
      </div>
      <Services />
    </>
  );
};

export default ServicesPage;
