import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Header from "./components/Header/Header";
import HomePage from "./components/HomePage/HomePage";
import ServicesPage from "./components/Services/ServicesPage";
import ServiceDetails from "./components/ServiceDetails/ServiceDetails";
import Footer from "./components/Footer/Footer";
import ContactPage from "./components/Contact/ContactPage";
import ScrollToTop from "./components/ScrollToTop/ScrollToTop";
import WebDevelopment from "./assets/web-development.png";
import AppDevelopment from "./assets/app-development.png";
import DigitalMarketing from "./assets/digital-marketing.png";
import GraphicDesigning from "./assets/graphic-designing.jpg";
import WordPress from "./assets/wordpress.jpg";
import UIUX from "./assets/UiUx.jpg";

function App() {
  const servicesContent = [
    {
      title: "Web Development",
      img: WebDevelopment,
      heroText:
        "Transform your online presence with our comprehensive web development services. We create responsive, user-friendly websites tailored to meet your business needs, ensuring a seamless user experience and robust functionality.",
      approachText:
        "We follow a comprehensive approach to web development that ensures your website is not only visually appealing but also highly functional and optimized for performance. Our process includes:",
      approach1: "Consultation",
      approach1Details: "Understanding your business goals and requirements.",
      approach2: "Planning",
      approach2Details: "Crafting a detailed project plan and timeline.",
      approach3: "Design",
      approach3Details:
        "Creating a user-centric design that aligns with your brand.",
      approach4: "Development",
      approach4Details:
        "Writing clean, efficient code using the latest technologies.",
      approach5: "Testing",
      approach5Details:
        "Rigorous testing to ensure a bug-free, seamless experience.",
      approach6: "Launch",
      approach6Details: "Deploying your website and providing ongoing support.",
    },
    {
      title: "App Development",
      img: AppDevelopment,
      heroText:
        "Elevate your business with our bespoke app development services. We build intuitive, high-performing apps that cater to your business objectives and deliver a seamless user experience.",
      approachText:
        "We adopt a thorough approach to app development that guarantees your app is user-friendly, robust, and performs flawlessly. Our process includes:",
      approach1: "Consultation",
      approach1Details:
        "Understanding your app’s objectives and target audience.",
      approach2: "Planning",
      approach2Details: "Developing a strategic plan and roadmap for the app.",
      approach3: "Design",
      approach3Details: "Crafting an intuitive and engaging user interface.",
      approach4: "Development",
      approach4Details: "Building the app using cutting-edge technologies.",
      approach5: "Testing",
      approach5Details:
        "Conducting extensive testing to ensure a smooth, bug-free experience.",
      approach6: "Launch",
      approach6Details:
        "Releasing your app to the market and offering continuous support.",
    },
    {
      title: "UI/UX Designing",
      img: UIUX,
      heroText:
        "Enhance user satisfaction with our expert UI/UX designing services. We create visually appealing and intuitive designs that offer a seamless user experience.",
      approachText:
        "We emphasize a user-centered design process to create interfaces that are not only visually stunning but also intuitive and enjoyable to use. Our process includes:",
      approach1: "Research",
      approach1Details: "Understanding user needs and behaviors.",
      approach2: "Wireframing",
      approach2Details: "Creating wireframes to outline the app’s structure.",
      approach3: "Prototyping",
      approach3Details: "Developing interactive prototypes for user testing.",
      approach4: "Design",
      approach4Details: "Crafting visually appealing and functional designs.",
      approach5: "Testing",
      approach5Details:
        "Gathering user feedback to refine and improve the design.",
      approach6: "Implementation",
      approach6Details:
        "Collaborating with developers to ensure a seamless transition from design to development.",
    },
    {
      title: "Digital Marketing",
      img: DigitalMarketing,
      heroText:
        "Boost your online presence with our strategic digital marketing services. We tailor our approach to meet your business goals, ensuring maximum reach and engagement.",
      approachText:
        "We implement a strategic approach to digital marketing, ensuring your brand reaches its target audience effectively. Our process includes:",
      approach1: "Analysis",
      approach1Details:
        "Evaluating your current digital presence and identifying opportunities.",
      approach2: "Strategy",
      approach2Details: "Developing a customized digital marketing strategy.",
      approach3: "Content Creation",
      approach3Details: "Crafting engaging and relevant content.",
      approach4: "SEO & SEM",
      approach4Details:
        "Optimizing your website and running targeted ad campaigns.",
      approach5: "Social Media",
      approach5Details: "Managing and growing your social media presence.",
      approach6: "Analytics",
      approach6Details:
        "Monitoring performance and refining strategies for better results.",
    },
    {
      title: "WordPress Development",
      img: WordPress,
      heroText:
        "Build a powerful online presence with our WordPress development services. We create feature-rich, secure, and easy-to-manage websites tailored to your needs.",
      approachText:
        "We follow a meticulous approach to WordPress development, ensuring your site is feature-rich, secure, and easy to manage. Our process includes:",
      approach1: "Consultation",
      approach1Details: "Identifying your website’s goals and requirements.",
      approach2: "Planning",
      approach2Details: "Creating a detailed project plan and timeline.",
      approach3: "Design",
      approach3Details: "Designing a custom theme that reflects your brand.",
      approach4: "Development",
      approach4Details: "Developing your site using WordPress best practices.",
      approach5: "Testing",
      approach5Details:
        "Ensuring functionality and compatibility across devices.",
      approach6: "Launch",
      approach6Details:
        "Deploying your website and providing training and support.",
    },
    {
      title: "Graphic Designing",
      img: GraphicDesigning,
      heroText:
        "Make a lasting impression with our graphic designing services. We create high-quality, impactful visuals that effectively communicate your brand message.",
      approachText:
        "We adopt a creative and strategic approach to graphic designing, ensuring your visuals are impactful and aligned with your brand identity. Our process includes:",
      approach1: "Consultation",
      approach1Details: "Understanding your design needs and brand vision.",
      approach2: "Research",
      approach2Details: "Analyzing trends and gathering inspiration.",
      approach3: "Conceptualization",
      approach3Details: "Developing creative concepts and ideas.",
      approach4: "Design",
      approach4Details:
        "Creating high-quality designs that convey your message.",
      approach5: "Feedback",
      approach5Details: "Incorporating your feedback to refine the designs.",
      approach6: "Delivery",
      approach6Details:
        "Providing the final designs in various formats for use across different platforms.",
    },
  ];

  const servicesOfferContent = [
    {
      heading1: "Custom Website Development",
      content1:
        "Our custom website development services are crafted to deliver a unique online presence that truly represents your brand. We work closely with you to understand your specific business goals and requirements, ensuring that the final product is not only visually stunning but also functionally robust. From a simple landing page to a complex, multi-functional website, our developers build sites that are scalable, efficient, and easy to manage. Whether you need a sleek corporate website or an interactive platform, we tailor every aspect to suit your exact needs.",
      heading2: "E-Commerce Solutions",
      content2:
        "We provide comprehensive e-commerce solutions that are designed to maximize your online sales and improve customer engagement. Our team builds secure, scalable, and feature-rich e-commerce platforms that offer seamless shopping experiences. From product management and payment gateway integration to advanced analytics and personalized shopping experiences, we cover all aspects of e-commerce. Our solutions are designed to drive conversions, increase customer retention, and provide an effortless shopping experience across all devices.",
      heading3: "Responsive Design",
      content3:
        "In today's digital landscape, having a responsive website is crucial. Our responsive design services ensure that your website looks great and functions perfectly on all devices, whether it’s a desktop, tablet, or smartphone. We design with a mobile-first approach, optimizing every aspect of your site to ensure a seamless user experience across all screen sizes. This not only enhances the user experience but also improves your website's performance in search engine rankings.",
      heading4: "Content Management Systems",
      content4:
        "Managing your website content should be simple and straightforward. That's why we offer easy-to-use Content Management Systems (CMS) like WordPress, Joomla, and Drupal. Our CMS solutions are designed to give you full control over your website, allowing you to update content, add new pages, and manage media without needing technical expertise. We customize these platforms to match your needs, providing you with a powerful and flexible tool to keep your website fresh and up-to-date.",
      heading5: "Web Maintenance",
      content5:
        "A website is never truly finished; it requires ongoing attention to remain functional, secure, and up-to-date. Our web maintenance services ensure that your website continues to perform at its best long after launch. We offer regular updates, security checks, bug fixes, and performance optimizations to keep your site running smoothly. Whether it's adding new features, updating content, or ensuring compatibility with the latest technologies, we provide continuous support to keep your website in peak condition.",
    },
    {
      heading1: "Custom App Development",
      content1:
        "Our custom app development services are designed to create unique and effective mobile and web applications tailored to your specific business needs. Whether you require a native app for iOS or Android, or a cross-platform solution, our experienced developers use the latest technologies to build applications that are both user-friendly and functional. From initial concept to final launch, we ensure that your app meets the highest standards of quality and performance.",
      heading2: "Mobile App Solutions",
      content2:
        "We specialize in developing high-quality mobile apps that deliver exceptional user experiences. Our mobile app solutions cover a range of functionalities, including GPS, push notifications, and in-app purchases. We work closely with you to understand your target audience and create apps that are engaging, intuitive, and optimized for both iOS and Android platforms.",
      heading3: "Web App Development",
      content3:
        "Our web app development services focus on creating powerful and interactive web applications that run smoothly across all devices. We use modern frameworks and technologies to build scalable and efficient web apps that meet your business objectives. From complex enterprise applications to simple tools, we ensure that your web app is reliable, secure, and performs well.",
      heading4: "UI/UX Design for Apps",
      content4:
        "User experience is critical to the success of any app. Our UI/UX design services focus on creating visually appealing and highly functional interfaces that enhance user satisfaction. We conduct thorough research and testing to ensure that your app is not only aesthetically pleasing but also easy to navigate and use.",
      heading5: "App Maintenance and Support",
      content5:
        "Post-launch support is essential to keep your app running smoothly. Our app maintenance services include regular updates, bug fixes, performance monitoring, and feature enhancements. We are committed to ensuring that your app continues to perform well and meets evolving user needs.",
    },
    {
      heading1: "SEO Services",
      content1:
        "Our SEO services are designed to improve your website’s visibility and ranking in search engine results. We conduct thorough keyword research, optimize on-page elements, and build high-quality backlinks to enhance your site’s search engine performance and drive more organic traffic.",
      heading2: "Content Marketing",
      content2:
        "Content is key to engaging your audience and driving conversions. We create and implement content marketing strategies that include blog posts, articles, infographics, and videos tailored to your audience’s interests and needs. Our content is designed to build brand authority, generate leads, and foster customer loyalty.",
      heading3: "Social Media Marketing",
      content3:
        "Effective social media marketing helps you connect with your audience and build your brand presence. We develop and execute social media strategies that include content creation, community management, and paid advertising to drive engagement and grow your following on platforms like Facebook, Instagram, Twitter, and LinkedIn.",
      heading4: "Email Marketing",
      content4:
        "Email marketing remains one of the most effective ways to reach your audience. We design and manage email campaigns that are targeted, personalized, and designed to drive engagement. From newsletters to promotional offers, our email marketing strategies aim to increase open rates, click-through rates, and conversions.",
      heading5: "Paid Advertising",
      content5:
        "Maximize your ROI with our paid advertising services. We manage PPC campaigns on platforms like Google Ads and social media networks to drive targeted traffic to your website. Our approach includes keyword research, ad creation, and performance monitoring to ensure your ads reach the right audience and deliver measurable results.",
    },
    {
      heading1: "User Interface Design",
      content1:
        "Our UI design services focus on creating intuitive and visually appealing interfaces that enhance user interaction. We design layouts, navigation systems, and interactive elements to ensure that your application is user-friendly and aligns with your brand’s aesthetics.",
      heading2: "User Experience Design",
      content2:
        "A great user experience is essential for the success of any digital product. We conduct user research, create user personas, and perform usability testing to design experiences that are both efficient and enjoyable. Our UX design process ensures that your product meets the needs of your users and provides a seamless experience.",
      heading3: "Prototyping and Wireframing",
      content3:
        "Before diving into full-scale development, we create prototypes and wireframes to map out the structure and functionality of your application. This helps visualize the user flow and design, allowing for early feedback and adjustments to ensure the final product meets your expectations.",
      heading4: "Usability Testing",
      content4:
        "Testing is a crucial part of the design process. We perform thorough usability testing to identify potential issues and gather feedback from real users. This ensures that your product is easy to use, meets user expectations, and provides a positive experience.",
      heading5: "Design Systems",
      content5:
        "We develop comprehensive design systems that include guidelines for typography, color schemes, and component libraries. This ensures consistency across all elements of your application and helps streamline the design and development process.",
    },
    {
      heading1: "Custom WordPress Themes",
      content1:
        "We design and develop custom WordPress themes tailored to your brand and business needs. Our themes are not only visually appealing but also optimized for performance and user experience. We ensure that your WordPress site stands out and functions seamlessly.",
      heading2: "Plugin Development",
      content2:
        "Extend the functionality of your WordPress site with custom plugins. We develop plugins that add specific features and capabilities to your site, enhancing its functionality and meeting your unique requirements.",
      heading3: "WordPress Migration",
      content3:
        "If you’re moving your website to WordPress from another platform, we handle the migration process smoothly and efficiently. We ensure that your content, design, and functionality are preserved during the transition, providing a seamless experience for your users.",
      heading4: "WordPress Maintenance",
      content4:
        "Keep your WordPress site running smoothly with our maintenance services. We offer regular updates, security monitoring, and performance optimization to ensure that your site remains secure, fast, and functional.",
      heading5: "WordPress SEO",
      content5:
        "Optimize your WordPress site for search engines with our SEO services. We conduct on-page optimization, keyword research, and content improvements to enhance your site’s visibility and search engine ranking.",
    },
    {
      heading1: "Brand Identity Design",
      content1:
        "Our graphic design services start with creating a strong brand identity that sets you apart from the competition. We design logos, business cards, letterheads, and other branding materials that reflect your company’s values and resonate with your target audience. Our designs are crafted to leave a lasting impression and build a cohesive brand image.",
      heading2: "Marketing Materials",
      content2:
        "Effective marketing materials are key to capturing your audience’s attention. We design eye-catching brochures, flyers, posters, and banners that effectively communicate your message and promote your products or services. Our designs are tailored to align with your marketing strategy and help you achieve your business goals.",
      heading3: "Digital Graphics",
      content3:
        "In the digital age, having compelling visuals for online platforms is crucial. We create engaging graphics for social media, websites, email campaigns, and more. Our digital graphics are designed to enhance your online presence, attract attention, and drive engagement.",
      heading4: "Print Design",
      content4:
        "From magazines and books to packaging and promotional items, our print design services cover a wide range of needs. We ensure that your print materials are not only visually appealing but also high quality and effective in delivering your message.",
      heading5: "Infographics",
      content5:
        "Transform complex data into easy-to-understand visuals with our infographic design services. We create informative and visually appealing infographics that help convey your data and insights in a clear and engaging way.",
    },
  ];

  const servicesWhyUsContent = [
    {
      heading1: "Expertise",
      content1:
        "Our developers are skilled in the latest technologies, including HTML5, CSS3, JavaScript, React, Angular, and more.",
      heading2: "Customization",
      content2:
        "We understand that every business is unique, so we offer customized solutions tailored to your needs.",
      heading3: "SEO-Friendly",
      content3:
        "Our websites are optimized for search engines, ensuring that your site ranks well and attracts the right audience.",
      heading4: "Security",
      content4:
        "We prioritize the security of your website, implementing the latest practices to protect against vulnerabilities.",
      heading5: "Timely Delivery",
      content5:
        "We work efficiently to deliver your project on time without compromising on quality.",
    },
    {
      heading1: "Expertise",
      content1:
        "Our team excels in developing both native and cross-platform apps using technologies like Swift, Kotlin, React Native, and Flutter.",
      heading2: "User-Centric Design",
      content2:
        "We focus on creating intuitive and engaging user interfaces that enhance the user experience and drive engagement.",
      heading3: "Performance Optimization",
      content3:
        "We ensure that your app runs smoothly and efficiently across all devices, providing a seamless user experience.",
      heading4: "Security",
      content4:
        "Our apps are built with robust security measures to protect user data and ensure compliance with industry standards.",
      heading5: "Ongoing Support",
      content5:
        "We offer continuous support and updates to keep your app running smoothly and up to date with the latest trends.",
    },
    {
      heading1: "Creative Excellence",
      content1:
        "Our designers bring creativity and innovation to every project, ensuring a unique and visually appealing user experience.",
      heading2: "User-Focused",
      content2:
        "We design with the user in mind, creating intuitive interfaces that are easy to navigate and interact with.",
      heading3: "Research-Driven",
      content3:
        "Our design process is informed by extensive user research and testing to ensure the best possible outcomes.",
      heading4: "Consistency",
      content4:
        "We ensure a consistent and cohesive design language across all your platforms, reinforcing your brand identity.",
      heading5: "Timely Delivery",
      content5:
        "Our streamlined design process allows us to deliver high-quality designs within your project timeline.",
    },
    {
      heading1: "Innovative Concepts",
      content1:
        "Our graphic designers are experts in creating original and eye-catching designs that capture your brand's essence.",
      heading2: "Versatility",
      content2:
        "From logos to marketing materials, we offer a wide range of graphic design services to meet your needs.",
      heading3: "Attention to Detail",
      content3:
        "We pay close attention to the finer details, ensuring that every design element aligns with your vision.",
      heading4: "Brand Consistency",
      content4:
        "We maintain consistency across all your visual assets, helping to build a strong and recognizable brand identity.",
      heading5: "Responsive Communication",
      content5:
        "Our team works closely with you, ensuring your ideas are heard and integrated into the final design.",
    },
    {
      heading1: "Comprehensive Strategy",
      content1:
        "We offer end-to-end digital marketing solutions, including SEO, social media, content marketing, and PPC campaigns.",
      heading2: "Targeted Approach",
      content2:
        "We create campaigns that are tailored to reach your specific audience, driving traffic and conversions.",
      heading3: "Data-Driven",
      content3:
        "Our strategies are informed by data and analytics, ensuring that your marketing efforts are effective and measurable.",
      heading4: "ROI Focused",
      content4:
        "We prioritize return on investment, ensuring that your marketing budget is used effectively to achieve your business goals.",
      heading5: "Continuous Optimization",
      content5:
        "We continually monitor and adjust your campaigns to ensure they perform at their best.",
    },
    {
      heading1: "Specialized Knowledge",
      content1:
        "Our developers are WordPress experts, skilled in creating custom themes, plugins, and websites.",
      heading2: "SEO-Optimized",
      content2:
        "We build WordPress sites with SEO best practices in mind, helping you rank higher in search results.",
      heading3: "Responsive Design",
      content3:
        "Our WordPress sites are fully responsive, ensuring they look great on all devices, from desktops to smartphones.",
      heading4: "Easy Management",
      content4:
        "We make sure your WordPress site is easy to manage, with a user-friendly admin panel and comprehensive documentation.",
      heading5: "Support & Maintenance",
      content5:
        "We offer ongoing support and maintenance to keep your WordPress site secure, updated, and performing well.",
    },
  ];

  return (
    <Router>
      <ScrollToTop />
      <Routes>
        <Route
          path="/"
          element={
            <div>
              <Header /> <HomePage /> <Footer />
            </div>
          }
        />
        <Route
          path="/services"
          element={
            <div>
              <Header /> <ServicesPage /> <Footer />
            </div>
          }
        />
        <Route
          path="/web-development"
          element={
            <div>
              <Header />{" "}
              <ServiceDetails
                content={servicesContent[0]}
                servicesOfferContent={servicesOfferContent[0]}
                servicesWhyUsContent={servicesWhyUsContent[0]}
              />{" "}
              <Footer />
            </div>
          }
        />
        <Route
          path="/app-development"
          element={
            <div>
              <Header />{" "}
              <ServiceDetails
                content={servicesContent[1]}
                servicesOfferContent={servicesOfferContent[1]}
                servicesWhyUsContent={servicesWhyUsContent[1]}
              />{" "}
              <Footer />
            </div>
          }
        />
        <Route
          path="/digital-marketing"
          element={
            <div>
              <Header />{" "}
              <ServiceDetails
                content={servicesContent[3]}
                servicesOfferContent={servicesOfferContent[2]}
                servicesWhyUsContent={servicesWhyUsContent[4]}
              />{" "}
              <Footer />
            </div>
          }
        />
        <Route
          path="/ui-ux-designing"
          element={
            <div>
              <Header />{" "}
              <ServiceDetails
                content={servicesContent[2]}
                servicesOfferContent={servicesOfferContent[3]}
                servicesWhyUsContent={servicesWhyUsContent[2]}
              />{" "}
              <Footer />
            </div>
          }
        />
        <Route
          path="/wordpress-development"
          element={
            <div>
              <Header />{" "}
              <ServiceDetails
                content={servicesContent[4]}
                servicesOfferContent={servicesOfferContent[4]}
                servicesWhyUsContent={servicesWhyUsContent[5]}
              />{" "}
              <Footer />
            </div>
          }
        />
        <Route
          path="/graphic-designing"
          element={
            <div>
              <Header />{" "}
              <ServiceDetails
                content={servicesContent[5]}
                servicesOfferContent={servicesOfferContent[5]}
                servicesWhyUsContent={servicesWhyUsContent[3]}
              />{" "}
              <Footer />
            </div>
          }
        />
        <Route
          path="/contact"
          element={
            <div>
              <Header /> <ContactPage /> <Footer />
            </div>
          }
        />
      </Routes>
    </Router>
  );
}

export default App;
