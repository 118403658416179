import { useEffect } from "react";
import { Link } from "react-router-dom";
import image1 from "../../assets/image1.jpg";
import "./ServiceDetails.css";

import AOS from "aos";
import "aos/dist/aos.css";

const ServiceDetails = ({
  content,
  servicesOfferContent,
  servicesWhyUsContent,
}) => {
  useEffect(() => {
    AOS.init({
      duration: 1200, // Animation duration in milliseconds
    });
  }, []);

  return (
    <>
      {/* <div className="hero-section contact-hero-section">
        <div className="hero-title">
          <p className="hero-text">{content.title}</p>
          <p
            className="hero-text"
            style={{ fontSize: "19px", lineHeight: "1.3", fontWeight: "300" }}
          >
            {content.heroText}
          </p>
        </div>
        <div>
          <img src={content.img} className="hero-img" />
        </div>
      </div> */}

      <div className="web-development-services-page-hero" style={{background: `url(${content.img}) no-repeat center center/cover`}}>
        <div className="services-page-hero-content">
          <h1 className="services-page-title">{content.title}</h1>
          <h2 className="services-page-content">
          {content.heroText}
          </h2>
        </div>
      </div>

      <div class="approach_section" style={{ display: "none" }}>
        <h1 style={{ textAlign: "center" }}>Our Approach</h1>
        <p>{content.approachText}</p>
        <h4>{content.approach1}</h4>
        <p>{content.approach1Details}</p>
        <h4>{content.approach2}</h4>
        <p>{content.approach2Details}</p>
        <h4>{content.approach3}</h4>
        <p>{content.approach3Details}</p>
        <h4>{content.approach4}</h4>
        <p>{content.approach4Details}</p>
        <h4>{content.approach5}</h4>
        <p>{content.approach5Details}</p>
        <h4>{content.approach6}</h4>
        <p>{content.approach6Details}</p>
      </div>

      <section className="service-section">
        <h2>What We Offer</h2>
        <div className="offer_layout">
          <div className="offer_box" data-aos="fade-up">
            <h3 className="offer_heading">{servicesOfferContent.heading1}</h3>
            <p className="offer_content">{servicesOfferContent.content1}</p>
          </div>
          <div className="offer_box" data-aos="fade-up">
            <h3 className="offer_heading">{servicesOfferContent.heading2}</h3>
            <p className="offer_content">{servicesOfferContent.content2}</p>
          </div>
          <div className="offer_box" data-aos="fade-up">
            <h3 className="offer_heading">{servicesOfferContent.heading3}</h3>
            <p className="offer_content">{servicesOfferContent.content3}</p>
          </div>
          <div className="offer_box" data-aos="fade-up">
            <h3 className="offer_heading">{servicesOfferContent.heading4}</h3>
            <p className="offer_content">{servicesOfferContent.content4}</p>
          </div>
          <div className="offer_box" data-aos="fade-up">
            <h3 className="offer_heading">{servicesOfferContent.heading5}</h3>
            <p className="offer_content">{servicesOfferContent.content5}</p>
          </div>
        </div>
      </section>

      <section className="why-choose-us">
        <h2 style={{ color: "white" }}>Why Choose Us</h2>
        <div className="why-choose-us-layout">
          <div className="why-us-box">
            <h3 className="why-us-heading">{servicesWhyUsContent.heading1}</h3>
            <p className="why-us-content">{servicesWhyUsContent.content1}</p>
          </div>
          <div className="why-us-box">
            <h3 className="why-us-heading">{servicesWhyUsContent.heading2}</h3>
            <p className="why-us-content">{servicesWhyUsContent.content2}</p>
          </div>
          <div className="why-us-box">
            <h3 className="why-us-heading">{servicesWhyUsContent.heading3}</h3>
            <p className="why-us-content">{servicesWhyUsContent.content3}</p>
          </div>
          <div className="why-us-box">
            <h3 className="why-us-heading">{servicesWhyUsContent.heading4}</h3>
            <p className="why-us-content">{servicesWhyUsContent.content4}</p>
          </div>
          <div>
            <h3 className="why-us-heading">{servicesWhyUsContent.heading5}</h3>
            <p className="why-us-content">{servicesWhyUsContent.content5}</p>
          </div>
        </div>
      </section>

      <section id="our-approach">
        <h2>Our Approach</h2>
        <p>{content.approachText}</p>
        <div class="approach-steps">
          <div class="step">
            <h3>{content.approach1}</h3>
            <p>{content.approach1Details}</p>
          </div>
          <div class="step">
            <h3>{content.approach2}</h3>
            <p>{content.approach2Details}</p>
          </div>
          <div class="step">
            <h3>{content.approach3}</h3>
            <p>{content.approach3Details}</p>
          </div>
          <div class="step">
            <h3>{content.approach4}</h3>
            <p>{content.approach4Details}</p>
          </div>
          <div class="step">
            <h3>{content.approach5}</h3>
            <p>{content.approach5Details}</p>
          </div>
          <div class="step">
            <h3>{content.approach6}</h3>
            <p>{content.approach6Details}</p>
          </div>
        </div>
      </section>

      <div class="get-in-touch-section">
        <h1>READY TO GROW YOUR BUSINESS</h1>
        <div
          style={{
            width: "100%",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            flexDirection: "column",
            marginTop: "-10px",
          }}
        >
          <p className="grow-business-text">
            Unlock your company's full potential with our expert solutions
            tailored to your unique needs. From scaling operations to filling
            critical skill gaps, we're here to support your growth journey. Let
            us help you innovate and achieve your business goals effortlessly.
          </p>
          <Link
            to="/contact"
            style={{ textDecoration: "none", color: "white" }}
          >
            <button className="button">Get Started Today</button>
          </Link>
        </div>
      </div>
    </>
  );
};

export default ServiceDetails;
